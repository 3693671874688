import { Goal } from "./util/Goal"
import { Card, CardBody, Stack, StackDivider, Heading, Box, Button } from '@chakra-ui/react'
import { Calendar } from "./Calendar"
import { CapitalStore } from "../stores/Index";
import { Stat, StatHelpText, StatNumber } from "@chakra-ui/react"
import { NumericFormat } from 'react-number-format';
import { observer } from "mobx-react";
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react"

const Home = observer(() => {

    const auth = getAuth()

    const [userId, setUserId] = useState<null | string>(null)

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid)
            }
        })
    }, [auth, userId])

    const handleDissolve = () => {
        CapitalStore.dissolve(userId)
    }

    return (
        <div className="py-5 px-5 lg:px-0 grid grid-cols sm:grid-cols-3 gap-y-10 justify-center">
            <div className="sm:col-span-2 max-w-[348px] md:max-w-[488px] lg:max-w-[670px] xl:max-w-[754px]">
                <Calendar />
            </div>
            <div className="col-span-1 flex justify-center sm:justify-end">
                <div className="flex flex-col gap-y-4 w-[348px] md:w-[380px]">
                    <div className="drop-shadow-left">
                        <div className="drop-shadow-right">
                            <Card size={"sm"}>
                                <CardBody>
                                    <Stack divider={<StackDivider borderColor='#3e4044' />} spacing='4'>
                                        <Box>
                                            <div className="flex justify-between">
                                                <div>
                                                    <Heading size='xs' textTransform='uppercase'>
                                                        Goal
                                                    </Heading>
                                                    <Stat marginTop={"10px"}>
                                                        <StatHelpText className="font-bold text-green-700 text-md">
                                                            {CapitalStore.goal}%
                                                        </StatHelpText>
                                                    </Stat>
                                                </div>
                                                <Button isDisabled={!CapitalStore.goal} onClick={handleDissolve}>Dissolve</Button>
                                            </div>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Amount
                                            </Heading>
                                            <Stat>
                                                <StatNumber className="text-green-600 text-md">
                                                    ₹<NumericFormat displayType="text" value={(CapitalStore.capital * CapitalStore.goal) / 100} thousandsGroupStyle="lakh" thousandSeparator="," />
                                                </StatNumber>
                                            </Stat>
                                        </Box>
                                        <Box>
                                            <Heading size='xs' textTransform='uppercase'>
                                                Progress
                                            </Heading>
                                            <Goal />
                                        </Box>
                                    </Stack>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default Home