import { getAuth } from "firebase/auth"
import { useEffect } from "react"
import { app } from "../firebase.config"

export const Error = () => {
    const auth = getAuth(app)

    const onLogout = async () => {
        await auth.signOut()
    }

    useEffect(() => {
        onLogout()
    })

    return <h1 className="flex justify-center items-center font-bold h-screen max-w-screen p-5">The site is under maintenance. Please check after some time. We regret for your inconvenience.</h1>
}

export default Error