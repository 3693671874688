import { useEffect, useState, useRef } from "react"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { app, db } from "../firebase.config"
import { doc, setDoc, getDoc, serverTimestamp, Timestamp } from "firebase/firestore"

export const useAuthStatus = () => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [checkingStatus, setCheckingStatus] = useState(true)
    const isMounted = useRef(true)

    const addUser = async (user: any) => {
        const docRef = doc(db, "users", user.uid)
        const docSnap = await getDoc(docRef)
        if (!docSnap.exists()) {
            await setDoc(doc(db, "users", user.uid), {
                name: user.displayName,
                email: user.email,
                photoURL: user.photoURL,
                timestamp: serverTimestamp(),
                createdDate: user.metadata.createdAt,
                trial: true,
                subscribed: false,
                trialEndDate: Timestamp.fromDate(new Date(new Date().getTime() + 8 * 24 * 60 * 60 * 1000))
            })
        }
        //commenting for now as no payment integration
        // else {
        //     if (docSnap.get("trialEndDate") < Timestamp.now()) {
        //         await updateDoc(docRef, {
        //             trial: false,
        //         })
        //     }
        //     if (docSnap.get("subscriptionEndDate") < Timestamp.now()) {
        //         await updateDoc(docRef, {
        //             subscribed: false,
        //         })
        //     }
        //     const docSnap2 = await getDoc(docRef)
        //     if (docSnap2.get("trial") === false && docSnap2.get("subscribed") === false) {
        //         CapitalStore.setReadOnly(true)
        //     }
        // }
    }

    useEffect(() => {
        if (isMounted) {
            const auth = getAuth(app)
            onAuthStateChanged(auth, (user) => {
                if (user) {
                    addUser(user)
                    setLoggedIn(true)
                }
                setCheckingStatus(false)
            })
        }

        return () => {
            isMounted.current = false
        }
    }, [isMounted])

    return { loggedIn, checkingStatus }
}
