import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Divider, Slide, Text, ScaleFade } from '@chakra-ui/react'
import { ReactComponent as LogoIcon } from '../assets/logo.svg'
import { ReactComponent as Twitter } from '../assets/twitter2.svg'
import { ReactComponent as Youtube } from '../assets/youtube.svg'

import journalScreen from '../assets/journalScreen.jpeg'
import goal from '../assets/goal.jpeg'
import { CapitalStore } from '../stores/Index'
import { useEffect, useState } from 'react'
import Typewriter from '../components/util/Typewriter/Typewriter'

export const Landing = () => {

    const [cta, setCta] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setCta(true)
        }, 400)
    }, [])

    return (
        <>
            <div className="snap-start px-4">
                <div className="h-screen overflow-hidden">
                    <div className='flex justify-between py-4 sticky top-0 z-50 bg-white'>
                        <LogoIcon width='188.82px' height='40px' />
                        <div className='hidden sm:flex'>
                            <Button onClick={() => CapitalStore.setLanding(false)} bgColor={"#3e4044"} colorScheme='blackAlpha'>Try beta for Free</Button>
                        </div>
                    </div>
                    <div className='grid grid-cols gap-4 justify-center'>
                        <ScaleFade style={{
                            position: "relative",
                            transition: 'ease-in-out',
                            transitionDuration: "500ms"
                        }} in={true} initialScale={0.85}>
                            <div className='flex flex-col py-4 sm:py-8 gap-y-4 h-max items-center justify-center'>
                                <h1 className='mt-0 sm:mt-8 text-4xl sm:text-5xl md:text-6xl text-gray-900 font-semibold'>Don't just trade. Journal it.</h1>
                                <h2 className='flex justify-center text-xl sm:w-5/6 text-gray-700 sm:text-2xl md:text-2xl w-4/7 md:leading-10 font-medium'>
                                    <ScaleFade initialScale={0} in={cta} style={{
                                        position: 'relative',
                                        transition: 'ease-in-out',
                                        transitionDuration: "500ms"
                                    }}
                                    >
                                        <Typewriter text="Easy to use self journaling tool for traders who want to become better." delay={50} />
                                    </ScaleFade>
                                </h2>
                                <Box>
                                    <ScaleFade initialScale={0} in={cta} style={{
                                        position: 'relative',
                                        transition: 'ease-in-out',
                                        transitionDuration: "500ms"
                                    }}
                                    >
                                        <Button size={"lg"} onClick={() => CapitalStore.setLanding(false)} bgColor={"#3e4044"} colorScheme='blackAlpha'>Get Started</Button>
                                    </ScaleFade>
                                </Box>
                            </div>
                        </ScaleFade>
                        <Box>
                            <Slide style={{
                                position: "relative",
                                transition: 'ease',
                                transitionDuration: "600ms"
                            }} direction='bottom' in={true}>
                                <Box>
                                    <img className="sm:mt-4" src={journalScreen} alt="" />
                                </Box>
                            </Slide>
                        </Box>
                    </div>
                </div>
            </div>
            <div className="snap-start px-4 h-screen justify-center overflow-hidden">
                <div className='grid grid-cols gap-4 justify-center'>
                    <h1 className='flex justify-center mt-8 sm:mt-16 text-4xl sm:text-5xl md:text-6xl font-semibold text-gray-900'>Make a goal to accomplish.</h1>
                    <img className="mt-4 sm:mt-16" src={goal} alt="" />
                </div>
            </div>
            <div className="snap-start px-4 h-screen overflow-hidden">
                <div className='mt-4 sm:mt-8'>
                    <Text className='text-xl sm:w-5/6 sm:text-2xl md:text-3xl py-8 sm:py-12 text-gray-900' fontWeight='bold'>
                        Frequently Asked Questions
                    </Text>
                    <Accordion defaultIndex={[0]} allowMultiple>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box fontWeight={"500"} as="span" flex='1' textAlign='left'>
                                        Why Superjournal?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                Superjournal help's you track your daily trading activity. It gives you an edge by learning from your mistake.                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box fontWeight={"500"} as="span" flex='1' textAlign='left'>
                                        How long does its take for getting access to Superjournal?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                You can start using right away!
                            </AccordionPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <h2>
                                <AccordionButton>
                                    <Box fontWeight={"500"} as="span" flex='1' textAlign='left'>
                                        How to contact us?
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <p> For any query or feedback you can contact us at <a className='text-blue-700 text-base' href="mailto:contact@superjournal.in">contact@superjournal.in</a></p>
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                </div>
                <footer className="pt-10 sm:pt-20 pb-60 sm:pb-40">
                    <Divider />
                    <div className="flex sm:flex-col gap-y-4 px-2">
                        <div className="sm:flex justify-between mt-4">
                            <p className="text-xl flex flex-col justify-between sm:text-2xl font-semibold text-gray-500 dark:text-gray-300">
                                © 2023 Superjournal
                                <span className='flex flex-row'>
                                    <p><a className='text-blue-700 text-base' href="mailto:contact@superjournal.in">contact@superjournal.in</a></p>
                                </span>
                            </p>
                            <div className='flex flex-col justify-between'>
                                <div className='flex sm:flex-row-reverse items-baseline gap-x-4 sm:gap-x-8 pt-8 sm:pt-0'>
                                    <a target={"_blank"} rel="noreferrer" href="https://twitter.com/superjournal_"><Twitter className='h-8 sm:h-12' fill='grey' height={"48px"} width={"48px"} /></a>
                                    <a target={"_blank"} rel="noreferrer" href="https://www.youtube.com/@superjournal"><Youtube className='h-8 sm:h-12' fill='grey' height={"48px"} width={"48px"} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </>)
}

export default Landing
