import googleIcon from "../assets/googleIcon.svg"
import { getAuth, signInWithRedirect, GoogleAuthProvider, getRedirectResult } from "firebase/auth"
import { app, db } from "../firebase.config"
import { doc, setDoc, getDoc, serverTimestamp } from "firebase/firestore"
import { toast } from "react-toastify"
import "../styles/frame.css"
import { ReactComponent as LandingSvg } from "../assets/frame.svg"
import { CapitalStore } from "../stores/Index"
import { observer } from "mobx-react-lite"
import Landing from "../pages/Landing"

export const OAuth = observer(() => {

    const onGoogleClick = async () => {
        try {
            const auth = getAuth(app)
            const provider = new GoogleAuthProvider()
            await signInWithRedirect(auth, provider)
            const result = await getRedirectResult(auth)

            if (result) {
                const user = result?.user
                const docRef = doc(db, "users", user.uid)
                const docSnap = await getDoc(docRef)
                if (!docSnap.exists()) {
                    await setDoc(doc(db, "users", user.uid), {
                        name: user.displayName,
                        email: user.email,
                        timestamp: serverTimestamp(),
                    })
                }
            }

            document.location.href = `/`;
        } catch (error) {
            toast.error("Could not authorize with google.")
        }
    }

    if (CapitalStore.landing) {
        // return <h1 className="flex justify-center items-center font-bold h-screen max-w-screen p-5">The site is under maintenance. Please check after some time. We regret for your inconvenience.</h1>
        return <Landing />
    }

    return (
        <div className="flex flex-col xl:flex-row gap-y-10 h-screen items-center">
            <div className="flex items-center xl:items-start h-2/6 xl:h-4/6 w-10/12">
                <div className="flex flex-col">
                    <h1 className="font-medium text-4xl sm:text-5xl my-10">Welcome to Superjournal</h1>
                    <button className="flex font-normal border text-base h-10 w-72 sm:w-96 justify-center items-center gap-x-4" onClick={onGoogleClick}>
                        <img width={"18px"} height={"18px"} className='socialIconImg' src={googleIcon} alt='google' />
                        <p>Continue with Google</p>
                    </button>
                </div>
            </div>
            <div className="flex items-start xl:items-center w-full">
                <LandingSvg className="h-5/6 w-11/12 md:h-4/6 xl:h-5/6" />
            </div>
        </div>
    )
})

export default OAuth