import { Button, ModalFooter, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react"
import { observer } from 'mobx-react'
import { CapitalStore } from "../stores/Index";

export interface BaseProps {
    isOpen: boolean
    onClose: () => void;
}

export const CapitalErrorMessage = observer(({ isOpen, onClose }: BaseProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <p className="font-light text-base sm:text-xl">{`Please add your trading ${!CapitalStore.capital ? 'capital &' : ''} goal.`}</p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});