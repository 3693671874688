import FinTrac from "./components/FinTrac"
import { ChakraProvider } from '@chakra-ui/react'
import { createContext, useState } from "react";

interface GlobalContextState {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const GlobalContext = createContext({} as GlobalContextState);

const App: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const value: GlobalContextState = {
    isOpen,
    setIsOpen
  }

  return (
    <GlobalContext.Provider value={value}>
      <ChakraProvider>
        <FinTrac />
      </ChakraProvider>
    </GlobalContext.Provider>
  )
}

export default App