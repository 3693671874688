import { useEffect, useState } from "react"
import { Header } from "./util/Calendar/Header"
import { Card, CardBody, Box } from '@chakra-ui/react'
import { Day } from "./util/Calendar/Day"
import { DayInfo } from "../models/day"
import { useDisclosure } from '@chakra-ui/react'
import { useDate } from "../hooks/useDate"
import { InputJournal } from "./util/InputJournal"
import { GetAccess } from "./util/GetAccess"
import { CapitalStore } from "../stores/Index"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useToast } from '@chakra-ui/react'
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../firebase.config";
import { observer } from "mobx-react"
import { usePayment } from "../hooks/usePayment"
import { CapitalErrorMessage } from "./CapitalErrorMessage"
import { EditJournal } from "./util/EditJournal"

export const Calendar = observer(() => {

    const [nav, setNav] = useState(0)

    const auth = getAuth()

    const [userId, setUserId] = useState<null | string>(null)

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid)
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )
                const querySnap = await getDocs(q)
                CapitalStore.setEvents(querySnap.docs[0]?.get('events') || [])
            }
        })
    }, [auth, userId])

    const { days, dateDisplay, sumReturn } = useDate(CapitalStore.events, nav);

    const [clicked, setClicked] = useState<string | undefined>()
    const toast = useToast()

    const eventForDate = (date: any) => CapitalStore.events.find((e: any) => date === e.date)

    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <div className="drop-shadow-left">
            <div className="drop-shadow-right">
                <Card>
                    <CardBody padding={"1rem"}>
                        <Box>
                            <Header sumReturn={sumReturn} dateDisplay={dateDisplay} onNext={() => setNav(nav + 1)}
                                onBack={() => setNav(nav - 1)} />
                        </Box>
                        <Box>
                            <div className="flex flex-wrap max-w-[308px] md:max-w-[448px] lg:max-w-[670px] xl:max-w-[754px]">
                                {days && days.map((d: DayInfo, index: number) => {
                                    return <Day key={index} day={d} onClick={() => {
                                        if (d.value !== 'padding') {
                                            setClicked(d.date);
                                            onOpen()
                                        }
                                    }} />
                                })}
                            </div>
                        </Box>
                    </CardBody>
                </Card>
                {
                    !CapitalStore.readOnly && clicked && !eventForDate(clicked) ?
                        CapitalStore.capital !== 0 && CapitalStore.goal !== 0 ?
                            <InputJournal onSave={async (data) => {
                                const result = await CapitalStore.updateEvents([...CapitalStore.events, { ...data, date: clicked }], userId as string, data.amount, data.type)
                                if (result) {
                                    toast({
                                        title: 'Could not update the details.',
                                        position: 'top',
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                } else {
                                    toast({
                                        title: 'Successfully updated the details.',
                                        position: 'top',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }
                                setClicked(undefined)
                            }} isOpen={isOpen} onClose={onClose} /> : <CapitalErrorMessage isOpen={isOpen} onClose={onClose} /> : !eventForDate(clicked) ? <GetAccess onSave={() => usePayment()} isOpen={isOpen} onClose={onClose} /> :
                            (<EditJournal event={eventForDate(clicked)!} onSave={async (data) => {
                                const newEvents = CapitalStore.events.filter((e) => e.date !== clicked)
                                const result = await CapitalStore.editEvents([...newEvents, { ...data, date: clicked }], userId as string)
                                if (result) {
                                    toast({
                                        title: 'Could not update the details.',
                                        position: 'top',
                                        status: 'error',
                                        duration: 9000,
                                        isClosable: true,
                                    })
                                } else {
                                    toast({
                                        title: 'Successfully updated the details.',
                                        position: 'top',
                                        status: 'success',
                                        duration: 3000,
                                        isClosable: true,
                                    })
                                }
                                setClicked(undefined)
                            }} isOpen={isOpen} onClose={onClose} />)
                }
            </div>
        </div>
    )
});
