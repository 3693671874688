import { StatGroup, Stat, StatLabel, StatNumber } from "@chakra-ui/react"
import { NumericFormat } from 'react-number-format';

export type dir = "increase" | "decrease"

export interface BaseProps {
    label: string;
    number: number;
    change: number;
}

export const Stats = ({ label, number, change }: BaseProps) => {
    return (
        <StatGroup>
            <Stat>
                <StatLabel>{label}</StatLabel>
                <StatNumber>
                    ₹<NumericFormat disabled={true} value={number} thousandsGroupStyle="lakh" thousandSeparator="," />
                </StatNumber>
            </Stat>
        </StatGroup>
    );
};