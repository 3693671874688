import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore"
import { CapitalStore } from "../../stores/Index";
import { db } from "../../firebase.config";
import { observer } from "mobx-react";


export const Goal = observer(() => {
    const auth = getAuth()

    const [userId, setUserId] = useState<null | string>(null)

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid)
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )
                const querySnap = await getDocs(q)
                CapitalStore.setProgress(querySnap.docs[0]?.get('progress') || 0)
            }
        })
    }, [auth, userId])

    return (
        <div className="flex justify-center">
            <CircularProgress size={"208px"} value={CapitalStore.progress >= 0 ? CapitalStore.progress : -(CapitalStore.progress)} color={CapitalStore.progress >= 0 ? 'green.400' : 'red.400'}>
                <CircularProgressLabel fontSize={"20px"}>{parseFloat((CapitalStore.progress).toFixed(3))}%</CircularProgressLabel>
            </CircularProgress>
        </div>
    );
});