import { Button, ModalFooter, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react"
import { observer } from 'mobx-react'

export interface BaseProps {
    isOpen: boolean
    onClose: () => void;
    onSave: () => void;
}

export const GetAccess = observer(({ isOpen, onClose, onSave }: BaseProps) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <p className="font-medium text-lg mb-4">Don't just trade. Journal it to grow better.</p>
                    <p className="font-light">Get Access now at just ₹99 / year.</p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                        onSave()
                        onClose()
                    }} colorScheme='blue' mr={3}>
                        Purchase
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});