import { Divider, Stat, StatHelpText, StatArrow } from "@chakra-ui/react";
import { ReactComponent as LeftIcon } from '../../../assets/leftIcon.svg'
import { ReactComponent as RightIcon } from '../../../assets/rightIcon.svg'

export interface HeaderProps {
    dateDisplay: any
    sumReturn: number
    onNext: () => void
    onBack: () => void
}

export const Header = ({ dateDisplay, sumReturn, onBack, onNext }: HeaderProps) => {
    return (
        <div className="mb-2 flex flex-col">
            <div className="mb-4 flex justify-between">
                <div className="flex items-center gap-x-2">
                    <div className="font-medium">{dateDisplay}</div>
                    <Stat><StatHelpText fontSize={"16px"} marginBottom={0}>
                        {sumReturn !== 0 && <StatArrow type={sumReturn > 0 ? "increase" : "decrease"} />}
                        {parseFloat((sumReturn).toFixed(3))}%
                    </StatHelpText></Stat>
                </div>
                <div className="flex gap-x-4">
                    <LeftIcon className="cursor-pointer" width='24px' height='24px' onClick={onBack} />
                    <RightIcon className="cursor-pointer" width='24px' height='24px' onClick={onNext} />
                </div>
            </div>
            <Divider borderColor='#3e4044' />
            <div className="mt-4 flex">
                <div className="">
                    <div className="flex border-[#181c29] rounded-md md:rounded-lg justify-center border w-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px]">Sun</div>
                </div>
                <div className="">
                    <div className="flex border-[#181c29] rounded-md md:rounded-lg justify-center border w-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px]">Mon</div>
                </div>
                <div className="">
                    <div className="flex border-[#181c29] rounded-md md:rounded-lg justify-center border w-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px]">Tue</div>
                </div>
                <div className="">
                    <div className="flex border-[#181c29] rounded-md md:rounded-lg justify-center border w-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px]">Wed</div>
                </div>
                <div className="">
                    <div className="flex border-[#181c29] rounded-md md:rounded-lg justify-center border w-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px]">Thu</div>
                </div>
                <div className="">
                    <div className="flex border-[#181c29] justify-center rounded-md md:rounded-lg border w-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px]">Fri</div>
                </div>
                <div className="">
                    <div className="flex border-[#181c29] justify-center rounded-md md:rounded-lg border w-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px]">Sat</div>
                </div>
            </div>
        </div>
    );
};