import { DayInfo } from "../../../models/day";
import { observer } from "mobx-react"

export interface DayProps {
    day: DayInfo
    onClick: () => void
}

export const Day = observer(({ day, onClick }: DayProps) => {
    return (
        <div onClick={onClick} className={`flex ${day.value !== 'padding' ? 'cursor-pointer border border-[#424448] rounded-md md:rounded-lg hover:bg-[#e8faed]' : ''} ${day.isCurrentDay ? 'bg-[#e8f4fa]' : ''} ${day.event?.type === 'profit' ? 'bg-[#c9eae1]' : day.event?.type === 'loss' ? 'bg-[#f6bfbe]' : ''} justify-center relative w-[44px] h-[44px] md:w-[64px] lg:w-[90px] xl:w-[102px] md:h-[64px] lg:h-[90px] xl:h-[102px]`}>
            <div className="flex flex-col justify-between w-full pt-1 pr-2">
                <span className="text-right">{day.value === 'padding' ? '' : day.value}</span>
                <span>
                    <span className="text-right items-center hidden lg:flex justify-end pb-1">
                        {day.event &&
                            <>
                                <span className="pr-px pl-2 text-lg">{day.event.type === 'profit' ? '+' : '-'}</span>
                                <span className="text-lg">₹</span>
                                <span className="truncate">{day.event.amount}</span>

                            </>}
                    </span>
                </span>
            </div>
        </div>
    );
});