import { Button, ModalFooter, Input, FormLabel, FormControl, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { CapitalStore } from "../../stores/Index";
import { observer } from 'mobx-react'
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useToast } from '@chakra-ui/react'
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../../firebase.config";

export interface BaseProps {
    isOpen: boolean
    onClose: () => void;
}

export const InputCapital = observer(({ isOpen, onClose }: BaseProps) => {
    const [capital, setCapital] = useState(CapitalStore.capital)
    const toast = useToast()

    useEffect(() => {
        setCapital(0)
    }, [isOpen])

    const [userId, setUserId] = useState<null | string>(null)

    const auth = getAuth()

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid)
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )
                const querySnap = await getDocs(q)
                CapitalStore.setCapital(querySnap.docs[0]?.get('capital') || 0)
            }
        })
    }, [auth, userId])

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <FormLabel>Capital</FormLabel>
                        <Input autoFocus onChange={(e) => setCapital(parseFloat(e.target.value))} value={capital || ''} type='number' placeholder='Enter your trading capital' />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={!capital ? true : false} onClick={async () => {
                        if (capital !== 0 && capital > 0) {
                            const result = await CapitalStore.updateCapital(capital, userId as string)
                            if (result) {
                                toast({
                                    title: 'Could not update the capital.',
                                    position: 'top',
                                    status: 'error',
                                    duration: 9000,
                                    isClosable: true,
                                })
                            } else {
                                toast({
                                    title: 'Successfully updated the capital.',
                                    position: 'top',
                                    status: 'success',
                                    duration: 3000,
                                    isClosable: true,
                                })
                            }
                            onClose()
                        }
                    }} colorScheme='blue' mr={3}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});