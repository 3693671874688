import { createChart, ColorType } from 'lightweight-charts';
import { RefObject, useEffect, useRef } from 'react';

export const ChartComponent = (props: any) => {
    const {
        data,
        colors: {
            backgroundColor = 'white',
            lineColor = '#2962FF',
            textColor = 'black',
            areaTopColor = '#2962FF',
            areaBottomColor = 'rgba(41, 98, 255, 0.28)',
        } = {},
    } = props;

    const chartContainerRef = useRef<HTMLInputElement>(null);

    useEffect(
        () => {
            const handleResize = () => {
                chart.applyOptions({ width: 900 });
            };

            const chart = createChart(chartContainerRef?.current as unknown as string, {
                layout: {
                    textColor: 'black', background: { type: 'solid' as ColorType, color: 'white' }
                },
                grid: {
                    horzLines: {
                        visible: false,
                    },
                },
                width: chartContainerRef?.current?.clientWidth,
                height: 300,
            });
            chart.timeScale().fitContent();

            const newSeries = chart.addLineSeries();
            newSeries.setData(data);

            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);

                chart.remove();
            };
        },
        [data, backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor]
    );

    return (
        <div
            ref={chartContainerRef as RefObject<HTMLDivElement>}
        />
    );
};