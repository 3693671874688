import { Stat, StatNumber, StatLabel, Highlight, Skeleton } from "@chakra-ui/react"
import { Card, CardBody } from '@chakra-ui/react'
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react"
import { collection, getDocs, query, where } from "firebase/firestore"
import { db } from "../firebase.config";
import { CapitalStore } from "../stores/Index"
import { Event } from "../models/day"
import { observer } from "mobx-react"
import { NumericFormat } from 'react-number-format';
import { ChartComponent } from "../components/chart/ChartComponent"
import { Tabs, TabList, Tab, TabPanel, TabPanels } from "@chakra-ui/tabs"
import Error from "./Error";

export const Reports = observer(() => {

    const auth = getAuth()

    const [userId, setUserId] = useState<null | string>(null)

    const [loading, setLoading] = useState<boolean>(true)

    const initialData: any = [
    ];

    useEffect(() => {
        onAuthStateChanged(auth, async (user) => {
            if (user) {
                setUserId(user.uid)
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )
                const querySnap = await getDocs(q)
                CapitalStore.setEvents(querySnap.docs[0]?.get('events') || [])
            }
            setLoading(false)
        })
    }, [auth, userId])

    let nP = 0, nL = 0
    let tP = 0, tL = 0

    let events = []

    events = CapitalStore.events.map(e => e)

    events.sort(function (a, b) {
        // Convert the date strings to Date objects
        let dateA = new Date(a.date as string);
        let dateB = new Date(b.date as string);

        // Subtract the dates to get a value that is either negative, positive, or zero
        return (dateA as any) - (dateB as any);
    })

    let amount = 0

    events.forEach((e: Event) => {
        let modifiedDate = e.date?.split('/').reverse()
        modifiedDate = modifiedDate?.map(item => item.padStart(2, '0')) as string[]
        let date = [modifiedDate[0], modifiedDate[2], modifiedDate[1]]
        let finalDate = date.join('-')
        // const date = new Date(Date.UTC(modifiedDate[0], modifiedDate?[1], modifiedDate?[2]))
        if (e.type === "profit") {
            amount += e.amount
        } else {
            amount -= e.amount
        }
        initialData.push({ time: finalDate, value: amount })

        if (e.type === 'profit') {
            nP++;
            tP += e.amount
        } else {
            nL++;
            tL += e.amount
        }
    })

    const winPer = nP / CapitalStore.events.length * 100

    if (loading) {
        return <div className="py-5 px-5 lg:px-0 grid grid-cols sm:grid-cols-3 gap-4">
            <Skeleton h='112px' />
            <Skeleton h='112px' />
            <Skeleton h='112px' />
        </div>
    }

    const isAllowed = () => {
        return auth?.currentUser && auth?.currentUser?.email === "borntrader09@gmail.com"
    }

    if (isAllowed()) {
        return (
            CapitalStore.events.length > 0 ?
                (
                    <div>
                        <Tabs colorScheme="gray" isLazy={true} isFitted variant='enclosed'>
                            <TabList mb='1em'>
                                <Tab>Statistics</Tab>
                                <Tab>Net PNL Graph</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <div className="py-5 px-5 lg:px-0 grid grid-cols md:grid-cols-3 gap-4">
                                        <div className="drop-shadow-left">
                                            <div className="drop-shadow-right">
                                                <Card className="h-28 flex justify-center">
                                                    <CardBody>
                                                        <div className="flex">
                                                            <div className="w-2/5 sm:w-1/3 border-[#4c4d519a] border-r-2">
                                                                <Stat>
                                                                    <StatLabel>Win %</StatLabel>
                                                                    <StatNumber fontSize={"20px"}>{winPer.toFixed(2)}%</StatNumber>
                                                                </Stat>
                                                            </div>
                                                            <div className="w-3/5 sm:w-2/3 flex flex-col gap-y-4 gap-x-4">
                                                                <div className="flex flex-wrap gap-x-4 justify-center">
                                                                    <Highlight
                                                                        query={nP.toString()}
                                                                        styles={{ px: '6', py: '1', rounded: 'full', bg: 'green.200' }}
                                                                    >
                                                                        {`Winners ${nP.toString()}`}
                                                                    </Highlight>
                                                                </div>
                                                                <div className="flex flex-wrap gap-x-4 justify-center">
                                                                    <Highlight
                                                                        query={nL.toString()}
                                                                        styles={{ px: '6', py: '1', rounded: 'full', bg: 'red.300' }}
                                                                    >
                                                                        {`Losers ${nL.toString()}`}
                                                                    </Highlight>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className="drop-shadow-left">
                                            <div className="drop-shadow-right">
                                                <Card className="h-28">
                                                    <CardBody>
                                                        <Stat>
                                                            <StatLabel>Average Daily Profit</StatLabel>
                                                            {/* <StatNumber>₹{Number.isNaN(tP / nP) ? 0 : (tP / nP).toFixed(2)}</StatNumber> */}
                                                            <span className="font-semibold text-2xl">₹<NumericFormat displayType="text" value={Number.isNaN(tP / nP) ? 0 : (tP / nP).toFixed(2)} thousandsGroupStyle="lakh" thousandSeparator="," /></span>
                                                        </Stat>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className="drop-shadow-left">
                                            <div className="drop-shadow-right">
                                                <Card className="h-28">
                                                    <CardBody>
                                                        <Stat>
                                                            <StatLabel>Average Daily Loss</StatLabel>
                                                            {/* <StatNumber>₹{Number.isNaN(tL / nL) ? 0 : (tL / nL).toFixed(2)}</StatNumber> */}
                                                            <span className="font-semibold text-2xl">₹<NumericFormat displayType="text" value={Number.isNaN(tL / nL) ? 0 : (tL / nL).toFixed(2)} thousandsGroupStyle="lakh" thousandSeparator="," /></span>
    
                                                        </Stat>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="py-5 px-5 lg:px-0 grid grid-cols md:grid-cols-1  gap-4">
                                        <div className="drop-shadow-left">
                                            <div className="drop-shadow-right">
                                                <Card className="flex justify-center">
                                                    <CardBody>
                                                        {initialData.length > 0 &&
                                                            <ChartComponent data={initialData}></ChartComponent>
                                                        }
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </div>
                ) :
                (<div className="py-5 px-5 lg:px-0 gap-4">
                    <div className="drop-shadow-left">
                        <div className="drop-shadow-right">
                            <Card className="flex items-center">
                                <CardBody>
                                    <Stat>
                                        <StatNumber>No Reports Found!</StatNumber>
                                    </Stat>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </div>)
        )
    }else{
        return <Error />
    }
});