import Header from "./util/Header";
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import Home from "./Home";
import PrivateRoute from "./PrivateRoute";
// import { Calculator } from "./util/Calculator";
import { Reports } from "../pages/Reports";

const FinTrac = () => {
    return (
        <div className="snap-y h-screen snap-mandatory overflow-auto">
            <div className='md:max-w-6xl mx-auto'>
                <Router>
                    <Switch>
                        <PrivateRoute exact path="/">
                            <Header />
                            <Home />
                        </PrivateRoute>
                        {/* <PrivateRoute exact path="/risk-reward-calculator">
                        <Header />
                        <Calculator />
                    </PrivateRoute> */}
                        <PrivateRoute exact path="/reports">
                            <Header />
                            <Reports />
                        </PrivateRoute>
                        <Route exact path="*">
                            <Redirect to={"/"} />
                        </Route>
                    </Switch>
                </Router>
            </div>
        </div>
    );
}

export default FinTrac;