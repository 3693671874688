import { useAuthStatus } from "../hooks/useAuthStatus"
import Spinner from "./Spinner/Spinner"
import { Route } from 'react-router-dom';
import OAuth from "./OAuth";
import { getAuth } from "firebase/auth";
import { app } from "../firebase.config"
import Error from "../pages/Error";

const PrivateRoute = ({ children, ...rest }: any) => {
    const { loggedIn, checkingStatus } = useAuthStatus()

    const auth = getAuth(app)

    const isAllowed = () => {
        return auth?.currentUser && auth?.currentUser?.email === "borntrader09@gmail.com"
    }

    if (checkingStatus) {
        return <Spinner />
    }

    if (!loggedIn) {
        return <OAuth />
    }

    if (isAllowed()) {
        return (
            <Route
                {...rest}
                render={() => {
                    return children;
                }}
            />
        );
    }
    else {
        return <Error />
    }

}

export default PrivateRoute
