import { Button, ModalFooter, Input, FormControl, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react"
import { observer } from 'mobx-react'
import { useEffect, useState } from "react";
import { RadioGroup, Stack, Radio } from '@chakra-ui/react'
import { CapitalStore } from "../../stores/Index";
import { Event } from '../../models/day'
import { Textarea, Text } from '@chakra-ui/react'

export interface BaseProps {
    isOpen: boolean
    onClose: () => void;
    event: Event;
    onSave: (data: Event) => void;
}

export const EditJournal = observer(({ isOpen, onClose, onSave, event }: BaseProps) => {
    const [edit, setEdit] = useState<boolean>(false)
    const [journalData, setJournalData] = useState({
        type: event.type,
        amount: event.amount,
        notes: event.notes,
        remarks: event.remarks
    })

    useEffect(() => {
        setJournalData({
            type: event.type,
            amount: event.amount,
            notes: event.notes,
            remarks: event.remarks
        })
        setEdit(false)
    }, [event, isOpen])

    const {
        type,
        amount,
        notes,
        remarks
    } = journalData

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <div className="flex flex-col gap-y-6">
                            <RadioGroup isDisabled={true} id="type" onChange={(e) => setJournalData((prevState) => ({ ...prevState, type: e }))} value={type}>
                                <Stack spacing={8} direction='row'>
                                    <Radio value='profit'>Profit</Radio>
                                    <Radio value='loss'>Loss</Radio>
                                </Stack>
                            </RadioGroup>
                            <Input isDisabled={true} value={amount || ''} onChange={(e) => setJournalData((prevState) => ({ ...prevState, amount: parseFloat(e.target.value) }))} type='number' placeholder={`What is the amount of ${type}?`} />
                            <div>
                                <Text mb={"8px"}>Notes</Text>
                                <Textarea value={notes} onChange={(e) => {
                                    setJournalData((prevState) => ({ ...prevState, notes: e.target.value }))
                                    if (!edit) {
                                        setEdit(true)
                                    }
                                }} placeholder='' />
                            </div>
                            <div>
                                <Text mb={"8px"}>Remarks</Text>
                                <Textarea value={remarks} onChange={(e) => {
                                    setJournalData((prevState) => ({ ...prevState, remarks: e.target.value }))
                                    if (!edit) {
                                        setEdit(true)
                                    }
                                }} placeholder='' />
                            </div>
                        </div>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={!edit} onClick={async () => {
                        onSave({ ...journalData, return: journalData.type === 'profit' ? +(amount / CapitalStore.capital * 100).toFixed(2) : -Math.abs(+(amount / CapitalStore.capital * 100).toFixed(2)) })
                        onClose()
                    }} colorScheme='blue' mr={3}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});