// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBZFEx2-Ak8XO27SQoPctxjvUNtfn0kujo",
    authDomain: "superjournal.in",
    projectId: "super-journal",
    storageBucket: "super-journal.appspot.com",
    messagingSenderId: "698225960265",
    appId: "1:698225960265:web:ed77fcab4f9a004bf96698"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)
