import { action, makeAutoObservable, observable } from 'mobx'
import { db } from "../firebase.config"
import { addDoc, collection, deleteField, doc, getDocs, query, updateDoc, where } from "firebase/firestore"
import { Event } from '../models/day'

export class CapitalStore {
    capital: number = 0
    progress: number = 0
    goal: number = 0
    events: Event[] = []
    readOnly: boolean = false
    landing: boolean = true

    constructor() {
        makeAutoObservable(this, {
            capital: observable,
            readOnly: observable,
            progress: observable,
            goal: observable,
            events: observable,
            updateCapital: action,
            setCapital: action,
            updateGoal: action,
            clearCapital: action,
            updateEvents: action,
            setProgress: action,
            setReadOnly: action,
            setEvents: action,
            dissolve: action
        })
    }

    setReadOnly(readOnly: boolean) {
        this.readOnly = readOnly
    }

    setLanding(landing: boolean) {
        this.landing = landing
    }

    setCapital(capital: number) {
        this.capital = capital
    }

    setProgress(progress: number) {
        this.progress = progress
    }

    setGoal(goal: number) {
        this.goal = goal
    }

    setEvents(events: Event[]) {
        this.events = events
    }

    async updateCapital(capital: number, userId: string | null) {
        if (userId) {
            try {
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )

                const querySnap = await getDocs(q)

                if (querySnap.docs.length !== 0) {
                    const docRef = doc(db, "items", querySnap.docs[0].id)
                    await updateDoc(docRef, { capital: capital, userRef: userId })
                }
                else {
                    await addDoc(collection(db, "items"), { capital: capital, userRef: userId })
                }
                this.setCapital(capital)
            } catch (error) {
                return error
            }
        }
    }

    async updateGoal(goal: number, userId: string | null) {
        if (userId) {
            try {
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )

                const querySnap = await getDocs(q)

                if (querySnap.docs.length !== 0) {
                    const docRef = doc(db, "items", querySnap.docs[0].id)
                    await updateDoc(docRef, { goal: goal, goalAmount: (this.capital * goal) / 100, remainingAmount: (this.capital * goal) / 100 })
                }
                else {
                    await addDoc(collection(db, "items"), { goal: goal, goalAmount: (this.capital * goal) / 100, remainingAmount: (this.capital * goal) / 100 })
                }
                this.setGoal(goal)
            } catch (error) {
                return error
            }
        }
    }

    async updateEvents(data: Event[], userId: string | null, amount: number, type: string) {
        if (userId) {
            try {
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )

                const querySnap = await getDocs(q)

                if (querySnap.docs.length !== 0) {
                    const docRef = doc(db, "items", querySnap.docs[0].id)
                    if (this.goal !== 0) {
                        await updateDoc(docRef, { events: data, remainingAmount: type === 'profit' ? querySnap.docs[0]?.get('remainingAmount') - amount : querySnap.docs[0]?.get('remainingAmount') + amount })
                    } else {
                        await updateDoc(docRef, { events: data })
                    }
                }
                else {
                    if (this.goal !== 0) {
                        await addDoc(collection(db, "items"), { events: data, userRef: userId, remainingAmount: type === 'profit' ? querySnap.docs[0]?.get('remainingAmount') - amount : querySnap.docs[0]?.get('remainingAmount') + amount })
                    } else {
                        await addDoc(collection(db, "items"), { events: data, userRef: userId })
                    }
                }

                const querySnap2 = await getDocs(q)
                const docRef = doc(db, "items", querySnap2.docs[0].id)
                if (this.goal !== 0) {
                    await updateDoc(docRef, { progress: ((querySnap2.docs[0]?.get('goalAmount') - querySnap2.docs[0]?.get('remainingAmount')) / querySnap2.docs[0]?.get('goalAmount')) * 100 })
                    this.setProgress(((querySnap2.docs[0]?.get('goalAmount') - querySnap2.docs[0]?.get('remainingAmount')) / querySnap2.docs[0]?.get('goalAmount')) * 100)
                }
                this.setEvents(data)
            } catch (error) {
                return error
            }
        }
    }

    async editEvents(data: Event[], userId: string | null) {
        if (userId) {
            try {
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )

                const querySnap = await getDocs(q)

                if (querySnap.docs.length !== 0) {
                    const docRef = doc(db, "items", querySnap.docs[0].id)
                    await updateDoc(docRef, { events: data })

                }
                this.setEvents(data)
            } catch (error) {
                return error
            }
        }
    }

    async dissolve(userId: string | null) {
        if (userId) {
            try {
                const itemsRef = collection(db, "items")
                const q = query(
                    itemsRef,
                    where("userRef", "==", userId),
                )

                const querySnap = await getDocs(q)
                const docRef = doc(db, "items", querySnap.docs[0].id)
                await updateDoc(docRef, { capital: this.capital + querySnap.docs[0]?.get('goalAmount') * (querySnap.docs[0]?.get('progress') || 0) / 100, })
                await updateDoc(docRef, {
                    "goal": deleteField(),
                    "goalAmount": deleteField(),
                    "progress": deleteField(),
                    "remainingAmount": deleteField()
                });
                this.setCapital(this.capital + querySnap.docs[0]?.get('goalAmount') * (querySnap.docs[0]?.get('progress') || 0) / 100)
                this.setGoal(0)
                this.setProgress(0)
            } catch (error) {
                return error
            }
        }
    }

    clearCapital() {
        this.capital = 0
        this.goal = 0
        localStorage.clear()
    }
}

export default CapitalStore;