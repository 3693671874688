import { getAuth } from "firebase/auth"
import { doc, updateDoc, serverTimestamp, Timestamp } from "firebase/firestore"
import { db } from "../firebase.config"
import { CapitalStore } from "../stores/Index";

export const usePayment = () => {

    const auth = getAuth()

    const [name, email, uid] = [auth?.currentUser?.displayName, auth?.currentUser?.email, auth?.currentUser?.uid]

    const loadScript = async (src: string) => {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    const displayRazorpay = async () => {
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
        if (!res) {
            alert('Failed to load razorpay')
            return
        }

        const options = {
            "key": "rzp_test_TIZ8jwnsPtIjxS",
            "key_secret": "Pc5jbDa2W1Y0UEGEfVyGu7Yn",
            "amount": "10000",
            "currency": "INR",
            "name": "Superjournal", //your business name
            "description": "Test Transaction",
            "handler": async function (response: any) {
                if (response.razorpay_payment_id) {
                    const docRef = doc(db, "users", uid as string)
                    await updateDoc(docRef, {
                        subscribed: true,
                        payment_id: response.razorpay_payment_id,
                        startDate: serverTimestamp(),
                        subscriptionEndDate: Timestamp.fromDate(new Date(new Date().getTime() + 366 * 24 * 60 * 60 * 1000))
                    })
                    CapitalStore.setReadOnly(false)
                }
                else {
                    alert(response.error.description)
                }
            },
            "prefill": {
                "name": name,
                "email": email,
            },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
        };
        const _window = window as any;
        var rzp1 = new _window.Razorpay(options);
        rzp1.open();
    }

    displayRazorpay()
};
