import { Button } from '@chakra-ui/react'
import { Menu as ChakraMenu, MenuButton, MenuList, MenuItem, MenuDivider } from '@chakra-ui/react'
import { useDisclosure } from '@chakra-ui/react'
import { InputCapital } from "./InputCapital";
import { useLocation, Link } from 'react-router-dom';
// import { ReactComponent as Calculator } from '../../assets/calculator.svg'
import { ReactComponent as Reports } from '../../assets/reports.svg'
import { ReactComponent as HomeIcon } from '../../assets/homeIcon.svg'
import { ReactComponent as LogoutIcon } from '../../assets/logoutIcon.svg'
import { ReactComponent as ChevronRightIcon } from '../../assets/chevronRightIcon.svg'
import { CapitalStore } from '../../stores/Index';
import { observer } from 'mobx-react'
import { InputGoal } from './InputGoal';
import { getAuth } from "firebase/auth"

const Menu = observer(() => {
    const auth = getAuth()
    const loc = useLocation()
    let text = loc.pathname.split('/')
    text = text.filter(item => item !== "")

    let link = "/"
    const { isOpen, onOpen, onClose } = useDisclosure()
    const {
        isOpen: isOpenGoal,
        onOpen: onOpenGoal,
        onClose: onCloseGoal
    } = useDisclosure()

    const [name, email, photoURL] = [auth?.currentUser?.displayName, auth?.currentUser?.email, auth?.currentUser?.photoURL]

    const onLogout = async () => {
        await auth.signOut()
        document.location.href = `/`;
    }

    // const loadScript = async (src: string) => {
    //     return new Promise((resolve) => {
    //         const script = document.createElement('script')
    //         script.src = src
    //         script.onload = () => {
    //             resolve(true)
    //         }
    //         script.onerror = () => {
    //             resolve(false)
    //         }
    //         document.body.appendChild(script)
    //     })
    // }

    // Not being used currently
    // const displayRazorpay = async () => {
    //     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')
    //     if (!res) {
    //         alert('Failed to load razorpay')
    //         return
    //     }

    //     const options = {
    //         "key": "rzp_test_TIZ8jwnsPtIjxS",
    //         "key_secret": "Pc5jbDa2W1Y0UEGEfVyGu7Yn",
    //         "amount": "10000",
    //         "currency": "INR",
    //         "name": "Superjournal", //your business name
    //         "description": "Test Transaction",
    //         "handler": async function (response: any) {
    //             if (response.razorpay_payment_id) {
    //                 const docRef = doc(db, "users", uid as string)
    //                 await updateDoc(docRef, {
    //                     subscribed: true,
    //                     payment_id: response.razorpay_payment_id,
    //                     startDate: serverTimestamp(),
    //                     subscriptionEndDate: Timestamp.fromDate(new Date(new Date().getTime() + 366 * 24 * 60 * 60 * 1000))
    //                 })
    //             }
    //             else {
    //                 alert(response.error.description)
    //             }
    //         },
    //         "prefill": {
    //             "name": name,
    //             "email": email,
    //         },
    //         "notes": {
    //             "address": "Razorpay Corporate Office"
    //         },
    //     };
    //     const _window = window as any;
    //     var rzp1 = new _window.Razorpay(options);
    //     rzp1.open();
    // }

    return (
        <>
            <div className="sticky top-0 z-50 grid gap-4 md:gap-0 md:grid-cols-2 bg-white mt-0 mb-5 md:my-5">
                <div className="relative flex justify-end md:justify-start items-center h-10 my-auto">
                    <div className='flex text-2xl gap-x-2 items-center'>
                        <Link className="underline text-blue-600 hover:text-blue-800" to="/">
                            <HomeIcon width='24px' height='24px' />
                        </Link>
                        {
                            text.map((item: string, index: number) => {
                                link += item + '/'
                                if (index !== text.length - 1) {
                                    return (
                                        <div key={index} className='flex items-center gap-x-2'><div key={index}><ChevronRightIcon width='20px' height='20px' /></div><Link className="underline text-gray-600 hover:text-gray-800" to={link}>{item.split('-').map(item => item.substring(0, 1).toUpperCase() + item.substring(1)).join(' ')}</Link></div>
                                    )
                                }
                                else {
                                    return (
                                        <div key={index} className='flex items-center gap-x-2'><div key={index}><ChevronRightIcon width='20px' height='20px' /></div><div>{item.split('-').map(item => item.substring(0, 1).toUpperCase() + item.substring(1)).join(' ')}</div></div>
                                    )
                                }
                            })
                        }
                    </div>
                </div>
                <div className="relative flex items-center justify-end space-x-4">
                    <div className="flex flex-wrap justify-end gap-x-4 gap-y-4">
                        <div className="flex justify-end"><Button isDisabled={!!CapitalStore.goal} onClick={onOpen}>{CapitalStore.capital !== 0 ? 'Update Capital' : 'Add Capital'}</Button></div>
                        <div className="flex justify-end"><Button isDisabled={!CapitalStore.capital || !!CapitalStore.goal} onClick={onOpenGoal}>Set Goal</Button></div>
                        {/* <div className="flex justify-end"><Button isDisabled={!CapitalStore.capital} colorScheme="red" onClick={() => CapitalStore.clearCapital()}>Reset</Button></div> */}
                        {photoURL &&
                            <div className='flex items-center justify-center'>
                                <ChakraMenu autoSelect={false}>
                                    <MenuButton
                                        transition='all 0.2s'
                                        _hover={{ opacity: '90%' }}
                                    >
                                        <img src={photoURL} alt="Profile pic" className='h-10 w-10 rounded-full cursor-pointer mx-auto hover:opacity-50' />
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem closeOnSelect={false} className='hover:bg-transparent'>{name}</MenuItem>
                                        <MenuItem closeOnSelect={false} className='hover:bg-transparent text-xs'>{email}</MenuItem>
                                        <MenuDivider />
                                        {/* <MenuItem onClick={() => document.location.href = '/risk-reward-calculator'} className='flex gap-x-2 text-sm'><Calculator width='16px' height='16px' />R/R Calculator</MenuItem> */}
                                        <MenuItem onClick={() => document.location.href = '/reports'} className='flex gap-x-2 text-sm'><Reports width='16px' height='16px' />Reports</MenuItem>
                                        {/* <MenuItem onClick={displayRazorpay} className='flex gap-x-2 text-sm'><Calculator width='16px' height='16px' />Purchase</MenuItem> */}
                                        <MenuItem onClick={onLogout} className='flex gap-x-2 text-sm'><LogoutIcon width='16px' height='16px' />Log out</MenuItem>
                                    </MenuList>
                                </ChakraMenu>
                            </div>}
                    </div>
                </div>
                <InputCapital isOpen={isOpen} onClose={onClose} />
                <InputGoal isOpen={isOpenGoal} onClose={onCloseGoal} />
            </div>
        </>
    )
})

export default Menu