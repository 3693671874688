import { useEffect, useState } from 'react';
import { DayInfo, Event } from '../models/day';

export const useDate = (events: any, nav: number) => {
    const [dateDisplay, setDateDisplay] = useState('');
    const [days, setDays] = useState<DayInfo[]>([])
    const [sumReturn, setSumReturn] = useState<number>(0)

    useEffect(() => {
        const eventForDate = (date: any) => events.find((e: any) => e.date === date)

        const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dt = new Date();

        if (nav !== 0) {
            dt.setMonth(new Date().getMonth() + nav);
        }

        const day = dt.getDate();
        const month = dt.getMonth();
        const year = dt.getFullYear();

        let sum = 0
        events.forEach((element: Event) => {
            let [m, y] = [element.date!.split('/')[0], element.date!.split('/')[2]]
            if (m === (month + 1).toString() && y === year.toString()) {
                sum += element.return!
            }
        });
        setSumReturn(sum)

        const firstDayOfMonth = new Date(year, month, 1);
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const dateString = firstDayOfMonth.toLocaleDateString('en-us', {
            weekday: 'long',
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
        });

        setDateDisplay(`${dt.toLocaleDateString('en-us', { month: 'long' })} ${year}`);
        const paddingDays = weekdays.indexOf(dateString.split(', ')[0]);

        const daysArr = [];

        for (let i = 1; i <= paddingDays + daysInMonth; i++) {
            const dayString = `${month + 1}/${i - paddingDays}/${year}`;

            if (i > paddingDays) {
                daysArr.push({
                    value: i - paddingDays,
                    event: eventForDate(dayString),
                    isCurrentDay: i - paddingDays === day && nav === 0,
                    date: dayString,
                });
            } else {
                daysArr.push({
                    value: 'padding',
                    event: null,
                    isCurrentDay: false,
                    date: '',
                });
            }
        }

        setDays(daysArr);
    }, [nav, events])

    return {
        days,
        dateDisplay,
        sumReturn
    };
};
