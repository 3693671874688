import { observer } from 'mobx-react'
import { CapitalStore } from "../../stores/Index";
import { Stats } from "./Stat";
import Menu from "./Menu";
import { ReactComponent as DownArrowIcon } from '../../assets/downArrowIcon.svg'
import { ReactComponent as CrossIcon } from '../../assets/crossIcon.svg'
import { ReactComponent as LogoIcon } from '../../assets/logo.svg'
import { useContext } from 'react'
import { GlobalContext } from '../../App'

const Header = observer(() => {
    const { isOpen, setIsOpen } = useContext(GlobalContext)

    return (
        <div className='sticky top-0 z-50 bg-white'>
            <div className=" flex flex-row justify-between md:grid md:grid-cols-2 py-5 px-5 lg:px-0">
                <div className='my-auto'>
                    <LogoIcon width='188.82px' height='40px' />
                </div>
                <div className="relative flex items-center justify-end space-x-4">
                    <div className="md:block pb-4 hidden">
                        <Stats label="Capital" number={CapitalStore.capital} change={10} />
                    </div>
                    {
                        isOpen ? (
                            <div className="md:hidden block">
                                <CrossIcon fill={'#161a28'} width='24px' height='24px' onClick={() => setIsOpen(!isOpen)} />
                            </div>
                        )
                            : (<div className="md:hidden block">
                                <DownArrowIcon fill={'#161a28'} width='24px' height='24px' onClick={() => setIsOpen(!isOpen)} />
                            </div>)
                    }
                </div>
                <div className='md:block hidden col-span-2'>
                    <Menu />
                </div>
            </div>
            {
                isOpen &&
                <div>
                    <div className='md:hidden mr-4'>
                        <div className='flex items-end flex-col-reverse'>
                            <Stats label="Capital" number={CapitalStore.capital} change={10} />
                            <Menu />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
})

export default Header