import { Button, ModalFooter, Input, FormControl, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody } from "@chakra-ui/react"
import { observer } from 'mobx-react'
import { useEffect, useState } from "react";
import { RadioGroup, Stack, Radio } from '@chakra-ui/react'
import { CapitalStore } from "../../stores/Index";
import { Event } from '../../models/day'
import { Textarea } from '@chakra-ui/react'

export interface BaseProps {
    isOpen: boolean
    onClose: () => void;
    onSave: (data: Event) => void;
}

export const InputJournal = observer(({ isOpen, onClose, onSave }: BaseProps) => {

    const [journalData, setJournalData] = useState({
        type: "profit",
        amount: 0,
        notes: "",
        remarks: ""
    })

    useEffect(() => {
        setJournalData({
            type: "profit",
            amount: 0,
            notes: "",
            remarks: ""
        })
    }, [isOpen])

    const {
        type,
        amount,
        notes,
        remarks
    } = journalData

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader></ModalHeader>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    <FormControl>
                        <div className="flex flex-col gap-y-6">
                            <RadioGroup id="type" onChange={(e) => setJournalData((prevState) => ({ ...prevState, type: e }))} value={type}>
                                <Stack spacing={8} direction='row'>
                                    <Radio value='profit'>Profit</Radio>
                                    <Radio value='loss'>Loss</Radio>
                                </Stack>
                            </RadioGroup>
                            <Input autoFocus value={amount || ''} onChange={(e) => setJournalData((prevState) => ({ ...prevState, amount: parseFloat(e.target.value) }))} type='number' placeholder={`What is the amount of ${type}?`} />
                            <Textarea maxHeight={"200px"} maxLength={300} value={notes} onChange={(e) => setJournalData((prevState) => ({ ...prevState, notes: e.target.value }))} placeholder='Notes' />
                            <Textarea maxHeight={"100px"} maxLength={100} value={remarks} onChange={(e) => setJournalData((prevState) => ({ ...prevState, remarks: e.target.value }))} placeholder='Remarks' />
                        </div>
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button isDisabled={!journalData.amount || journalData.amount <= 0 ? true : false} onClick={async () => {
                        onSave({ ...journalData, return: journalData.type === 'profit' ? +(amount / CapitalStore.capital * 100).toFixed(2) : -Math.abs(+(amount / CapitalStore.capital * 100).toFixed(2)) })
                        onClose()
                    }} colorScheme='blue' mr={3}>
                        Save
                    </Button>
                    <Button onClick={onClose}>Cancel</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
});